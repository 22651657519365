import Vue from 'vue'
import App from './App.vue'
import axios from "axios";

import '@/assets/main.scss'
import store from './store'
import router from './router'
import { VueMaskDirective } from 'v-mask';

console.log('VUE_APP_API_HOST: ', process.env.VUE_APP_API_HOST)
axios.defaults.baseURL = process.env.VUE_APP_API_HOST || '';
axios.defaults.headers.common['Content-Type'] = 'application/json';
let token = localStorage.getItem('token');
if (token){
  axios.defaults.headers.common['Authorization'] = "Bearer " + token;
}
axios.interceptors.response.use(null, (error) => {
  if (error.response && error.response.status === 401 && localStorage.getItem('refresh')){
    console.log('Catched response code 401 in interceptor, token refreshing');
    axios.post('/auth/token/refresh/', {
      "refresh": localStorage.getItem('refresh')
    }).then((res) => {
      console.log('Token Refreshed!');
      store.dispatch('session/set', {
        token: res.data.access,
        refresh: res.data.refresh,
      });
      error.config.headers.Authorization = "Bearer " + res.data.access;
      return axios.request(error.config);
    }).catch((err) => {
      console.error('token refresh error:', err)
      store.dispatch('user/logout');
      router.push({name: "Login"});
    })
  } 
  return Promise.reject(error);
  // Promise.reject(error);
});

Vue.directive('mask', VueMaskDirective);

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
