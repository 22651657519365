import Vue from 'vue'
import VueRouter from 'vue-router'

const RootPage = () => ({
  component: import(/* webpackChunkName: "sales-pages" */ '@/views/RootPage.vue'),
  // loading: ComponentLoadingPlaceholder,
  delay: 100,
});
const LoginPage = () => ({
  component: import(/* webpackChunkName: "sales-pages" */ '@/views/LoginPage.vue'),
  // loading: ComponentLoadingPlaceholder,
  delay: 100,
});
const InstructionsPage = () => ({
  component: import(/* webpackChunkName: "instruction-pages" */ '@/views/InstructionsPage.vue'),
  // loading: ComponentLoadingPlaceholder,
  delay: 100,
});
const PaymentPage = () => ({
  component: import(/* webpackChunkName: "payment-pages" */ '@/views/PaymentPage.vue'),
  // loading: ComponentLoadingPlaceholder,
  delay: 100,
});

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Root',
    component: RootPage,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/i/:phone',
    name: 'Instructions',
    component: InstructionsPage,
    props: route => ({ 
      phone: route.params.phone,
      exists: route.query.e,
    }),
    meta: {
      guest: true,
    }
  },
  {
    path: '/payment',
    name: 'Payment',
    component: PaymentPage,
    meta: {
      guest: true,
    }
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    component: LoginPage,
    meta: {
      guest: true,
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.getItem('token')) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else next()
  } else if (to.matched.some(record => record.meta.guest)) {
    if (!localStorage.getItem('token')) next()
    else next({ name: 'Root' })
  } else next()
});


export default router
