<template>
  <div>
    <div class="box">
      <Header 
        :username="full_name"
        :loggedIn="loggedIn"
      />
    </div>
    <router-view/>
  </div>
</template>
<script>
import Header from '@/components/layout/header.vue'
import { mapState } from "vuex"
export default {
  name: 'App',
  components: {
    Header,
  },
  computed: {
    ...mapState('session', {
      loggedIn: state => ( !!state.token )
    }),
    ...mapState('user', {
      full_name: state => ( state.full_name )
    })
  },
}
</script>
