import axios from "axios";

const state = {
  token: localStorage.getItem('token') || '',
  refresh: localStorage.getItem('refresh') || '',
}

const actions = {
  set({ commit }, { token = '', refresh = '', /* user */ }) {
    commit('setToken', token);
    commit('setRefresh', refresh);
    localStorage.setItem('token', token);
    localStorage.setItem('refresh', refresh);
    axios.defaults.headers.common['Authorization'] = "Bearer " + token;
  },

  delete({ commit }) {
    commit('setToken', '');
    commit('setRefresh', '')
    localStorage.removeItem('token');
    localStorage.removeItem('refresh')
    axios.defaults.headers.common['Authorization'] = '';
  }

};

const mutations = {
  setToken(state, value) {
    state.token = value;
  },
  setRefresh(state, value) {
    state.refresh = value;
  }
};

export const session = {
  namespaced: true,
  state,
  actions,
  mutations
};