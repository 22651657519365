const state = () => ({
  full_name: "",
  id: -1,
  email: "",
  groups: [],
})

const mutations = {
  // update state with new user
  set(state, { 
    email = "",
    full_name = "",
    groups = [],
    id = -1,
  }){
    Object.assign(state, { 
      email,
      full_name,
      groups,
      id,
     });
  },
  // updating existing user fields
  update(state, userFields ){
    Object.assign(state, userFields);
  },
  setLoading(state, value = true){
    state.loading = value;
  }
}

const actions = {
  // Initialize (after login)
  init: ({ commit }, payload) => {
    commit('set', { 
      ...payload,
    });
  },
  
  logout({commit, dispatch}){
    commit('set', {});
    // localStorage.removeItem('role_id')
    dispatch('session/delete', undefined, { root: true });
  },

  
}

const getters = {
  groups: (state) => (state.groups.length ? state.groups : undefined)
}

export const user = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}