<template>
  <div class="container is-max-desktop level">
    <div class="level-left">
      <img :src="require('@/assets/logo.svg')" alt="joo.kz" />
    </div>
    <div 
      v-if="loggedIn"
      class="level-right"
    >
      <span class="mr-2">{{ username }}</span>
      <a 
        v-if="loggedIn"
        href="#"
        class="level-item button is-danger"
        @click="logout"
        
      >
        Выйти  
      </a>

    </div>
  </div>
</template>

<script>

export default {
  props: {
    loggedIn: {
      type: Boolean,
      default: false,
    },
    username: {
      type: String,
      default: '',
    },
  },
  methods: {
    logout(){
      this.$store.dispatch('user/logout').then(() => {
        this.$router.push('/login')
      });
    }
  }
}
</script>